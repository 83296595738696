import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Form, Button, Table, Container, Alert, FloatingLabel, Row, Col, Card } from 'react-bootstrap';
import './OrderTracking.css';

const OrderTracking = ({ convertTime, servicesData, platformLogos, urlImg, emailIcon, mongoURI }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchType, setSearchType] = useState('email');
  const [email, setEmail] = useState('');
  const [platform, setPlatform] = useState(location.state?.platform || 'Instagram');
  const [usernameOrLink, setUsernameOrLink] = useState(location.state?.usernameOrLink || '');
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searched, setSearched] = useState(null);
  const [showMore, setShowMore] = useState({});
  const [searchFromUrl, setSearchFromUrl] = useState(false);
  const urlRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    const params = new URLSearchParams(location.search);
    const emailFromUrl = params.get('email');

    if (emailFromUrl) {
      setEmail(emailFromUrl);
      setSearchFromUrl(true);
    } else if (location.state?.platform && location.state?.usernameOrLink) {
      handleSearch();
    } else {
      setTimeout(() => urlRef.current?.focus(), 300);
    }
  }, [location]);

  useEffect(() => {
    if (searchFromUrl && email) {
      handleSearch();
    }
  }, [searchFromUrl, email]);

  const handlePlatformChange = (e) => {
    setSearched(null);
    setPlatform(e.target.value);
  };

  const handleUrlChange = (e) => {
    setSearched(null);
    setUsernameOrLink(e.target.value);
  };

  const handleEmailChange = (e) => {
    setSearched(null);
    setEmail(e.target.value);
  };

  const handleSearchTypeChange = (e) => {
    setSearchType(e.target.value);
    setSearched(null);
  };

  const handleSearch = async (e) => {
    if (e) e.preventDefault(); // Prevent form submission reload
    setLoading(true);
    setError(null);
    setSearched(searchType === 'email' ? email : `${platform}${usernameOrLink}`);

    try {
      const params = searchType === 'email' ? { email } : { platform, usernameOrLink };

      const response = await axios.get(
        `${mongoURI}getOrdersByEmailOrPlatformAndURL`,
        { params }
      );

      if (response.data.message) {
        setOrders([]);
        setError(response.data.message);
      } else {
        setOrders(response.data);
      }
    } catch (err) {
      setOrders([]);
      setError(err.response?.data.message || 'An error occurred while fetching the orders. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleShowMore = (platform) => {
    setShowMore((prevState) => ({ ...prevState, [platform]: !prevState[platform] }));
  };

  const sortByDateDescending = (ordersArray) => {
    if (!Array.isArray(ordersArray)) return [];
    return ordersArray.sort((a, b) => new Date(b.date) - new Date(a.date));
  };

  const renderTableForPlatform = (platformKey, platformOrders) => {
    const sortedOrders = sortByDateDescending(platformOrders);
    return (
      <div key={platformKey} className="mb-4">
        <h4>{platformKey}</h4>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Username/Post URL</th>
              <th>Service</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {sortedOrders.slice(0, showMore[platformKey] ? sortedOrders.length : 3).map((order) => (
              <tr key={order._id}>
                <td>{order.usernameOrLink}</td>
                <td>{order.quantity} {order.service}</td>
                <td>{order.service!=='Custom'?
                (order.status!=='Completed'?servicesData[platformKey][order.service]?.average_time?`Will be completed in approx ${convertTime(servicesData[platformKey][order.service]?.average_time)}`:"Paid. Processing":order.status):order.status}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {sortedOrders.length > 3 && (
          <Button variant="link" onClick={() => handleShowMore(platformKey)}>
            {showMore[platformKey] ? 'Show Less' : 'Show More'}
          </Button>
        )}
      </div>
    );
  };

  return (
    <Container className="order-tracking-container mt-5 mb-5">
      <Card className="order-tracking-card shadow-sm mx-auto">
        <Card.Body>
          <h1 className="text-center mb-4">Order Tracking</h1>
          <Form onSubmit={handleSearch} className="mb-4">
            <Row className="mb-3 justify-content-center">
              <Col xs="auto">
                <Form.Check 
                  type="radio" 
                  label="Search by Email" 
                  id="emailRadio"
                  value="email" 
                  checked={searchType === 'email'} 
                  onChange={handleSearchTypeChange} 
                  className="me-3"
                />
              </Col>
              <Col xs="auto">
                <Form.Check 
                  type="radio" 
                  label="Search by Username/URL" 
                  id="platformRadio"
                  value="platform" 
                  checked={searchType === 'platform'} 
                  onChange={handleSearchTypeChange} 
                  className="me-3"
                />
              </Col>
            </Row>

            {searchType === 'email' ? (
              <div className="d-flex align-items-center mb-3">
                <img src={emailIcon} alt="Email" className="platform-icon-small me-2" />
                <FloatingLabel controlId="emailInputTracking" label="Enter Email Address" className="flex-grow-1">
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                  />
                </FloatingLabel>
              </div>
            ) : (
              <>
                <div className="d-flex align-items-center mb-3">
                  {platform && (
                    <img src={platformLogos[platform]} alt={platform} className="platform-icon-small" />
                  )}
                  <FloatingLabel controlId="platformSelectTracking" label="Select Platform" className="flex-grow-1">
                    <Form.Select value={platform} onChange={handlePlatformChange} required>
                      {Object.keys(platformLogos).map((platformKey) => (
                        <option key={platformKey} value={platformKey}>
                          {platformKey}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </div>

                <div className="d-flex align-items-center mb-3">
                  <img src={urlImg} alt="Enter Profile/Post URL exactly as you entered when placing the order" className="platform-icon-small" />
                  <FloatingLabel controlId="urlInputTracking" label="Enter Username / Post URL" className="flex-grow-1">
                    <Form.Control
                      type="text"
                      placeholder="Enter username or post link"
                      value={usernameOrLink}
                      onChange={handleUrlChange}
                      ref={urlRef}
                      required
                    />
                  </FloatingLabel>
                </div>
              </>
            )}

            <div className="d-flex justify-content-center mb-3 search-btn">
              <Button type="submit" variant="info" disabled={loading || searched} style={{ width: '100%' }}>
                {loading ? 'Searching...' : 'Search'}
              </Button>
            </div>
          </Form>

          {searched && (
            <>
              {error && <Alert variant="danger">{error}</Alert>}
              {Array.isArray(orders) && orders.length > 0 ? (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Username/Post URL</th>
                      <th>Service</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortByDateDescending(orders).map((order) => (
                      <tr key={order._id}>
                        <td>{order.usernameOrLink}</td>
                        <td>{order.quantity} {order.service}</td>
                        <td>{order.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                Object.keys(orders).map((platformKey) => renderTableForPlatform(platformKey, orders[platformKey]))
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default OrderTracking;
